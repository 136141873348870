<template>
  <div>
    <router-view @updateList="getCategories()" />

    <FilterDrawer
      current-component="categories-filter"
      @filter="filterCategories"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.type }}</td>
        <td>{{ item.name }}</td>
        <td class="text-center">
          <StatusChip :current-status="item.status" />
        </td>
        <td class="text-right">
          {{ item.created_at }}
        </td>
      </template>
    </Table>
  </div>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import checkProfileRoutePermission from '../../../mixins/checkProfileRoutePermission'
import Table from '@/components/data-table/Table'
import StatusChip from '@/components/data-table/StatusChip'
import FilterDrawer from '@/components/FilterDrawer'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    dataTable: {
      title: 'Categorias',
      new: 'categories-new',
      edit: 'categories-edit',
      context: 'categories-context',
      massAction: 'categories-mass-action',
      totalPages: 1,
      totalRegister: 1,
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        { text: 'Tipo', value: 'type' },
        { text: 'Nome', value: 'name' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Data de criação', value: 'created_at', align: 'end' },
      ],
    },
  }),
  created() {
    this.getCategories()

    contextMenu.$once('action', (type) => {
      if (type === 'categories') {
        this.getCategories()
      }
    })
    massAction.$once('action', () => {
      this.getCategories()
    })
  },
  methods: {
    setCurrentPage(page) {
      this.getCategories(page)
    },
    search(search) {
      this.currentSearch = search
      this.getCategories()
    },
    getCategories(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('product_categories', {
          params: {
            page,
            search: this.currentSearch,
          }
        })
        .then((res) => {
          this.dataTable.totalPages = res.data.total_pages;
          this.dataTable.items = res.data.data
          this.dataTable.totalRegister = res.data.total_categories;
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterCategories(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('product_categories/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getCategories()
    },
  },
}
</script>
